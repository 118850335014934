import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
 
// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyDfin3B-w8UpmUpznA4reUCNbFcOGYwhgM",
  authDomain: "frisia-cooperado.firebaseapp.com",
  databaseURL: "https://frisia-cooperado.firebaseio.com",
  projectId: "frisia-cooperado",
  storageBucket: "frisia-cooperado.appspot.com",
  messagingSenderId: "143278267701",
  appId: "1:143278267701:web:62f2dde25d12023f58407c",
  measurementId: "G-7Y1JMHY3QQ",
});
 
// Firebase storage reference
const storage = getStorage(app);
export const auth = getAuth(app);
export default storage;